import Component from "@base/Component";
import Carousel from "@pages/home/Carousel";
import Report from "@pages/home/Report";
import Video from "@pages/home/Video";
import News from "@pages/home/News";
import Info from "@pages/home/Info";
import Partners from "@pages/home/Partners";


class Home extends Component {

    data = {}

    textTitle = 'Им нужна ваша помощь'
    textInfo = 'Вместе мы можем помочь взрослым и детям \n в их борьбе с тяжелыми заболеваниями!'


    onMount = () => {
        this.loadNewsData(this)
        // const script = document.createElement("script");
        // script.src = "https://widgets.donation.ru/wloader/9e33f4b9-a4db-4b9d-a51f-54a44a63db3b/wloader.js";
        // script.async = true;
        //
        // document.body.appendChild(script);
    }

    render() {

        return (
            <>
                {this.renderTitle(this.textTitle, this.textInfo)}
                <Carousel/>
                {/*<br/>*/}
                {/*<div id='a776f6f7-97a5-4b29-993d-afe7e41bb64b' data-type='mixplat-info-block'*/}
                {/*     data-comment='Карточка программы - Исполни мечту'></div>*/}
                <Video/>
                <Report/>
                <News data={this.data}/>
                <Info data={this.data}/>
                <Partners/>
            </>
        )
    }

}

export default Home;
